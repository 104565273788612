












import { Component, Vue, Prop } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import { History } from '@/repository/kontent/stores/historyStore';
import LocalNav from './LocalNav.vue';

@Component({
  components: {},
})
export default class YearItem extends Vue {
  @Prop(Object) historyItem!: History;
  private i18n = I18n;
  private get historyThumbnail() {
    return this.historyItem.thumbnail.value[0]?.url;
  }
}
