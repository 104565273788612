























import { Component, Vue, Prop } from 'vue-property-decorator';
import I18n from '@/i18n/index';
import { History } from '@/repository/kontent/stores/historyStore';
import LocalNav from './LocalNav.vue';
import HistoryItem from './HistoryItem.vue';

@Component({
  components: {
    HistoryItem,
  },
})
export default class YearItem extends Vue {
  @Prop(Array) yearItem!: [{ key: string; value: History }];
  private i18n = I18n;
}
