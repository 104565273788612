

















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { reactive } from '@vue/composition-api';
import I18n from '@/i18n/index';
import { SortOrder } from '@kentico/kontent-delivery';
import { HistoryStore } from '@/repository/kontent/stores/historyStore';
import LocalNav from './LocalNav.vue';
import HistoryYearItem from './HistoryYearItem.vue';

@Component({
  components: {
    LocalNav,
    HistoryYearItem,
  },
})
export default class HistoryPage extends Vue {
  private i18n = I18n;
  @Watch('i18n.language')
  onTextChanged(): void {
    this.historyData = reactive(new HistoryStore());
    this.historyData.setOrderParameter('elements.date', SortOrder.asc);
    this.historyData.restore();
  }

  public created(): void {
    this.historyData.setOrderParameter('elements.date', SortOrder.asc);
    this.historyData.restore();
  }
  private historyData = reactive(new HistoryStore());
  private get targetName() {
    return this.$route.name;
  }
}
