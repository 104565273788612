import Vue from 'vue';
import Store from './base/store';
import { History } from "../models/history";

class HistoryStore extends Store<History>{

  constructor(){
    super('history')
  }
  public getHistoryDataGroupByYear(){
    if (this.list.length == 0) return []
    const groupList =groupBy(this.list, r => r.date.value?.getFullYear())
    return groupList.sort()
  }
}

const groupBy = <K, V>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => K
): [K, V[]][] =>
  Array.from(
      array.reduce((map, cur, idx, src) => {
          const key = getKey(cur, idx, src);
          const list = map.get(key);
          if (list) list.push(cur);
          else map.set(key, [cur]);
          return map;
      }, new Map<K, V[]>())
  );

export { HistoryStore, History };
